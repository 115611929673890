import "../Style/MonthlyInvoiceStyle.css"
import { useEffect, useState, useContext } from "react";
import { Select, Spin, Table } from "antd";
import { useMsal } from "@azure/msal-react";
import { GetAccessToken } from "../Helper/JWTToken";
import { MainContext } from "../Components/Context";
import { Find, HasValue, IsStringNumber } from "../Helper/JSHelper";
import { GetMonthlyInvoiceData, GetVatRate, PostMonthlyInvocie } from "../Data Layer/Data";
import { Authorize, CheckAuthorization } from "../Helper/Authorization";
import NumericInput from "../Components/NumericInput";
import StandardSelect from "../Components/StandardSelect";
import SaveButton from "../Components/SaveButton";
import CancelButton from "../Components/CancelButton";
import ConfirmModal from "../Components/ConfirmModal";
import AlertModal from "../Components/AlertModal";

const { Option } = Select;

function defaultServiceData() {
    return {
        bookeepingLocal: {
            monthlyInvoiceId: null,
            subServiceId: null,
            present: false,
            payMethod: 0,
            net: null,
            serviceId:null,
            oldNet:null,
            oldPaymentMethod:0
        },
        bookeepingRecording: {
            present: false,
            payMethod: 0,
            net: null,
            paymentEditable: true,
            level: "",
            serviceId:null,
            oldNet:null,
            oldPaymentMethod:0

        },
        poac: {
            monthlyInvoiceId: null,
            subServiceId: null,
            present: false,
            net: null
        },
        prepaymentStandard: {
            monthlyInvoiceId: null,
            subServiceId: null,
            present: false,
            net: null
        },
        vat: {
            monthlyInvoiceId: null,
            subServiceId: null,
            present: false,
            net: null,
            serviceId:null,
            oldNet:null,
            oldPaymentMethod:0
        },
        shareCapital: {
            monthlyInvoiceId: null,
            subServiceId: null,
            present: false,
            net: null,
            oldNet:null,
            oldPaymentMethod:0
        },
        farmPro: {
            monthlyInvoiceId: null,
            subServiceId: null,
            present: false,
            payMethod: 0,
            net: null,
            level: "",
            isBespoke: false,
            serviceId:null,
            oldNet:null,
            oldPaymentMethod:0
        },
        payroll: {
            monthlyInvoiceId: null,
            subServiceId: null,
            present: false,
            payMethod: 0,
            net: null,
            level: "",
            isBespoke: false,
            serviceId:null,
            oldNet:null,
            oldPaymentMethod:0
        },
        unpaidDD: {
            monthlyInvoiceId: null,
            subServiceId: null,
            present: false,
            payMethod: 0,
            net: null,
            serviceId:null,
            oldNet:null,
            oldPaymentMethod:0
        },
        prepaymentTopUp: {
            monthlyInvoiceId: null,
            subServiceId: null,
            present: false,
            payMethod: 0,
            net: null,
            serviceId:null,
            oldNet:null,
            oldPaymentMethod:0
        },
    }
}

function MonthlyInvoicePage() {
    const context = useContext(MainContext);
    const msal = useMsal();

    const [isLoading, setIsLoading] = useState(false);
    const [invoiceData, setInvoiceData] = useState(null);
    const [serviceData, setServiceData] = useState(defaultServiceData());
    const [editAuthorized, setEditAuthorized] = useState(false);
    const [monthEditable, setMonthEditable] = useState(false);
    const [monthList, setMonthList] = useState([]);
    const [displayMonths, setDisplayMonths] = useState([]);
    const [selectedMonth, setSelectedMonth] = useState(null);
    const [message224, setMessage224] = useState("");
    const [message234, setMessage234] = useState("");
    const [message253, setMessage253] = useState("");
    const [hasBankDetails, setHasBankDetails] = useState(false);
    const [hasPayrollBankDetails, setHasPayrollBankDetails] = useState(false);
    const [isDirty, setIsDirty] = useState(false);
    // if client has DateHQAdded field is Null in the cms_ClientContacts table  is new client
    //Display an “*” before the Client Code on the top of each page 
    //Do not allow any field to be changed
    const [isNewClient,setIsNewClient]=useState(false); 
    const [saveEnabled, setSaveEnabled] = useState(false);
    
    useEffect(() => {
        async function fetchData(){
       // const token = await GetAccessToken(msal);
        const auth = await CheckAuthorization(["Monthly Invoice - Edit"], msal);

        setEditAuthorized(auth);
        }
        fetchData();
    }, [msal]);

    useEffect( () => {
      async function setData(){  if (HasValue(context.client.code)) {
            setIsLoading(true);
            const token = await GetAccessToken(msal);
            const data = await GetMonthlyInvoiceData(token, context.client.code);

            setMonthList(data.months);
            setDisplayMonths(data.displayMonth);
            setInvoiceData(data.invoices);
            setSelectedMonth(data.months.length - 1);
            setMessage224(data.message224);
            setMessage234(data.message234);
            setMessage253(data.message253);
            setHasBankDetails(data.hasBankDetails);
            setHasPayrollBankDetails(data.hasPayrollBankDetails);
            setIsLoading(false);
            if(HasValue(context.client.isNewClient)){
                if (context.client.isNewClient=='1'){
                    setIsNewClient(true);
                }else{
                    setIsNewClient(false);
                }
            }
        }
        else
            setInvoiceData(null);
    }
    setData();
    }, [context.client.code]);

    useEffect(() => {
        if (HasValue(invoiceData) && invoiceData.length > 0 && HasValue(selectedMonth)) {
            setServiceData(getServicesData(invoiceData, selectedMonth));
        }
        else
            setServiceData(defaultServiceData());

        setIsDirty(false);
    }, [invoiceData, selectedMonth])

    useEffect(() => {
        if (serviceData != null) {
            let enabled = true;

            if (selectedMonth != monthList.length - 1)
                enabled = false;

            if (serviceData.bookeepingLocal.present && (serviceData.bookeepingLocal.payMethod == 0 || !HasValue(serviceData.bookeepingLocal.net)))
                enabled = false;

            if (serviceData.vat.present && serviceData.bookeepingRecording.present && !HasValue(serviceData.vat.net))
                enabled = false;

            if (serviceData.bookeepingRecording.present && serviceData.bookeepingRecording.payMethod == 0)
                enabled = false;

            if (serviceData.farmPro.present && serviceData.farmPro.payMethod == 0)
                enabled = false;

            if (serviceData.farmPro.present && serviceData.farmPro.isBespoke && !HasValue(serviceData.farmPro.net))
                enabled = false;

            if (serviceData.payroll.present && serviceData.payroll.payMethod == 0)
                enabled = false;

                if (serviceData.payroll.present && serviceData.payroll.isBespoke && !HasValue(serviceData.payroll.net))
                enabled = false;

            if (serviceData.unpaidDD.present && serviceData.unpaidDD.payMethod == 0)
                enabled = false;
            setSaveEnabled(enabled);
        }
    }, [serviceData])

    function getServicesData(invoiceData, selectedMonth) {

        let data = defaultServiceData();


        const invoice = Find(invoiceData, "invoiceMonth", monthList[selectedMonth]);
        setMonthEditable(invoice.isEditable);

        let hasBR = false;

        invoice.services.forEach(service => {
            let addBR = false;

            switch (service.name) {
                //Bookeeping local
                case 0: {
                    data.bookeepingLocal.present = true;
                    data.bookeepingLocal.monthlyInvoiceId = service.monthlyInvoiceId;
                    data.bookeepingLocal.subServiceId = service.subServiceId;
                    data.bookeepingLocal.net = service.net;
                    data.bookeepingLocal.payMethod = service.payMethodId;
                    data.bookeepingLocal.vat = service.vat;
                    data.bookeepingLocal.serviceId=service.serviceId;
                    break;
                }
                //POAC
                case 2: {
                    data.poac.present = true;
                    data.poac.monthlyInvoiceId = service.monthlyInvoiceId;
                    data.poac.subServiceId = service.subServiceId;
                    data.poac.net = service.net;
                    data.poac.vat = service.vat;
                    if (!hasBR)
                        addBR = true;
                    break;
                }
                //PrepaymentStandard
                case 3: {
                    data.prepaymentStandard.present = true;
                    data.prepaymentStandard.monthlyInvoiceId = service.monthlyInvoiceId;
                    data.prepaymentStandard.subServiceId = service.subServiceId;
                    data.prepaymentStandard.net = service.net;
                    data.prepaymentStandard.vat = service.vat;
                    if (!hasBR)
                        addBR = true;
                    break;
                }
                //VAT
                case 4: {
                    data.vat.present = true;
                    data.vat.monthlyInvoiceId = service.monthlyInvoiceId;
                    data.vat.subServiceId = service.subServiceId;
                    data.vat.net = service.net;
                    data.vat.vat = service.vat;
                    data.vat.serviceId=service.serviceId;
                    break;
                }
                //ShareCapital
                case 5: {
                    data.shareCapital.present = true;
                    data.shareCapital.monthlyInvoiceId = service.monthlyInvoiceId;
                    data.shareCapital.subServiceId = service.subServiceId;
                    data.shareCapital.net = service.net;
                    data.shareCapital.vat = 0;
                    if (!hasBR)
                        addBR = true;
                    break;
                }
                //FarmPro
                case 6: {
                    data.farmPro.present = true;
                    data.farmPro.monthlyInvoiceId = service.monthlyInvoiceId;
                    data.farmPro.subServiceId = service.subServiceId;
                    data.farmPro.net = service.net;
                    data.farmPro.vat = service.vat;
                    data.farmPro.payMethod = service.payMethodId;
                    data.farmPro.level = service.level;
                    data.farmPro.isBespoke = service.isBespoke;
                    data.farmPro.serviceId=service.serviceId;
                    break;
                }
                //Payroll
                case 7: {
                    data.payroll.present = true;
                    data.payroll.monthlyInvoiceId = service.monthlyInvoiceId;
                    data.payroll.subServiceId = service.subServiceId;
                    data.payroll.net = service.net;
                    data.payroll.vat = service.vat;
                    data.payroll.payMethod = service.payMethodId;
                    data.payroll.level = service.level;
                    data.payroll.isBespoke = service.isBespoke;
                    data.payroll.serviceId=service.serviceId;
                    break;
                }
                //UnpaidDD
                case 8: {
                    data.unpaidDD.present = true;
                    data.unpaidDD.monthlyInvoiceId = service.monthlyInvoiceId;
                    data.unpaidDD.subServiceId = service.subServiceId;
                    data.unpaidDD.net = service.net;
                    data.unpaidDD.vat = service.vat;
                    data.unpaidDD.payMethod = service.payMethodId;
                    data.unpaidDD.serviceId=service.serviceId;
                    break;
                }
                //PrepaymentTopUp
                case 9: {
                    data.prepaymentTopUp.present = true;
                    data.prepaymentTopUp.monthlyInvoiceId = service.monthlyInvoiceId;
                    data.prepaymentTopUp.subServiceId = service.subServiceId;
                    data.prepaymentTopUp.net = service.net;
                    data.prepaymentTopUp.vat = service.vat;
                    data.prepaymentTopUp.payMethod = service.payMethodId;
                    data.prepaymentTopUp.serviceId=service.serviceId;
                    break;
                }
                default:{
                    //todo
                }
            }
            if (addBR) {
                data.bookeepingRecording.present = true;
                data.bookeepingRecording.payMethod = service.payMethodId;
                data.bookeepingRecording.level = service.level;
                data.bookeepingRecording.paymentEditable = invoice.brPaymentEditable;
                data.bookeepingRecording.serviceId = service.serviceId;
                hasBR = true;
            }
        });
        return data;
    }

    const setData = (val, service, field) => {

        //Ignore the change if new value is the same as old value
        if ((!HasValue(val) && !HasValue(serviceData[service][field])) || val === serviceData[service][field])
            return;

        if (field === "payMethod" && val == "1" && ((service !== "payroll" && !hasBankDetails) || (service === "payroll" && !hasPayrollBankDetails))) {
            AlertModal(message224, "Ok");
            val = 2;
        }

        //Do not allow change from DD to Cash for br, payroll and farmPro
        if (field === "payMethod" && val == "2" && serviceData[service][field] == 1  && (service === "bookeepingRecording" || service === "payroll" || service === "farmPro")) {
            let alert = message253;
            switch(service)
            {
                case "bookeepingRecording":
                    alert = alert.replace('"Service name"', 'Bookeeping Recording service');
                    break;
                case "payroll":
                    alert = alert.replace('"Service name"', 'Payroll service');
                    break;
                case "farmPro":
                    alert = alert.replace('"Service name"', 'Farm Pro service');
                    break;
            }
            AlertModal(alert, "Ok");
            val = 1;
        }

        if (field === "net" && service === "prepaymentTopUp" && !hasBankDetails) {
            AlertModal(message234, "Ok");
            val = '';
        }

        setIsDirty(true);

        var copy = { ...serviceData };
        if (isNaN(val) && val !== '') {
            return;
        }

        if (val === '' || ((val[val.length - 1] === '.' || val[val.length - 1] === '0')  && !isNaN(val)))
            copy[service][field] = val;
        else
            copy[service][field] = Number(val);

        setServiceData(copy);
    }

    const getGross = (service) => {

        if (!HasValue(service.net))
            return null;

        return Math.round(service.net * (1 + (service.vat ?? 0) / 100) * 100) / 100;
    }

    const getBRNetTotal = () => {
        const sum = Number(serviceData?.poac.net) + Number(serviceData?.prepaymentStandard.net) + Number(serviceData?.vat.net) + Number(serviceData?.shareCapital.net);
        return sum;
    }

    const getBRGrossTotal = () => {
        const sum = getGross(serviceData?.poac) + getGross(serviceData?.prepaymentStandard) + getGross(serviceData?.vat) + Number(serviceData?.shareCapital.net);
        return sum;
    }

    const getNetTotal = () => {
        const sum =
            Number(serviceData?.bookeepingLocal.net) +
            Number(serviceData?.poac.net) +
            Number(serviceData?.prepaymentStandard.net) +
            Number(serviceData?.vat.net) +
            Number(serviceData?.shareCapital.net) +
            Number(serviceData?.farmPro.net) +
            Number(serviceData?.payroll.net) +
            Number(serviceData?.unpaidDD.net) +
            Number(serviceData?.prepaymentTopUp.net);
        return sum;
    }

    const getGrossTotal = () => {
        const sum =
            getGross(serviceData?.bookeepingLocal) +
            getGross(serviceData?.poac) +
            getGross(serviceData?.prepaymentStandard) +
            getGross(serviceData?.vat) +
            Number(serviceData?.shareCapital.net) +
            getGross(serviceData?.farmPro) +
            getGross(serviceData?.payroll) +
            getGross(serviceData?.unpaidDD) +
            getGross(serviceData?.prepaymentTopUp);
        return sum;
    }

    const onMonthChanged = (val) => {
        const callback = () => {
            setSelectedMonth(Number(val));
            setIsDirty(false);
        }
        if (isDirty)
            ConfirmModal("Al changes made will be lost. Do you wish to proceed?", callback);
        else
            callback();
    }

    const cancelChanges = () => {
        const callback = () => {
            setServiceData(getServicesData(invoiceData, selectedMonth));
            setIsDirty(false);
        }
        if (isDirty)
            ConfirmModal("Al changes made will be lost. Do you wish to proceed?", callback);
        else
            callback();
    }

    const saveInvocie = async () => {
        let data = {
            clientID: context.client.code,
            transactions: []
        }

        const month = monthList[selectedMonth];

        if (serviceData.bookeepingLocal.present){
            var oldObj0=invoiceData[selectedMonth].services.find(x=> {return x.name===0});
            data.transactions.push({
                monthlyInvoiceId: serviceData.bookeepingLocal.monthlyInvoiceId,
                serviceName: 0,
                subServiceId: serviceData.bookeepingLocal.subServiceId,
                net: serviceData.bookeepingLocal.net,
                invoiceMonth: month,
                paymentMethod: serviceData.bookeepingLocal.payMethod,
                serviceId:serviceData.bookeepingLocal.serviceId,
                oldNet:oldObj0?.net,
                oldPaymentMethod:oldObj0?.payMethodId
            });
        }
        if (serviceData.poac.present){
            var oldObj2=invoiceData[selectedMonth].services.find(x=> {return x.name===2});
            data.transactions.push({
                monthlyInvoiceId: serviceData.poac.monthlyInvoiceId,
                serviceName: 2,
                subServiceId: serviceData.poac.subServiceId,
                net: serviceData.poac.net,
                invoiceMonth: month,
                paymentMethod: serviceData.bookeepingRecording.payMethod,
                serviceId:serviceData.bookeepingRecording.serviceId,
                oldNet:oldObj2?.net,
                oldPaymentMethod:oldObj2?.payMethodId
            });
        }
        if (serviceData.prepaymentStandard.present){
            var oldObj3=invoiceData[selectedMonth].services.find(x=> {return x.name===3});
            data.transactions.push({
                monthlyInvoiceId: serviceData.prepaymentStandard.monthlyInvoiceId,
                serviceName: 3,
                subServiceId: serviceData.prepaymentStandard.subServiceId,
                net: serviceData.prepaymentStandard.net,
                invoiceMonth: month,
                paymentMethod: serviceData.bookeepingRecording.payMethod,
                serviceId:serviceData.bookeepingRecording.serviceId,
                oldNet:oldObj3?.net,
                oldPaymentMethod:oldObj3?.payMethodId
            });
        }
        if (serviceData.vat.present){
            var oldObj4=invoiceData[selectedMonth].services.find(x=> {return x.name===4});
            data.transactions.push({
                monthlyInvoiceId: serviceData.vat.monthlyInvoiceId,
                serviceName: 4,
                subServiceId: serviceData.vat.subServiceId,
                net: serviceData.vat.net,
                invoiceMonth: month,
                paymentMethod: serviceData.bookeepingRecording.payMethod,
                serviceId:4,
                oldNet:oldObj4?.net,
                oldPaymentMethod:oldObj4?.payMethodId
            });
        }
        if (serviceData.shareCapital.present){
            var oldObj5=invoiceData[selectedMonth].services.find(x=> {return x.name===5});
            data.transactions.push({
                monthlyInvoiceId: serviceData.shareCapital.monthlyInvoiceId,
                serviceName: 5,
                subServiceId: serviceData.shareCapital.subServiceId,
                net: serviceData.shareCapital.net,
                invoiceMonth: month,
                paymentMethod: serviceData.bookeepingRecording.payMethod,
                serviceId:serviceData.bookeepingRecording.serviceId,
                oldNet:oldObj5?.net,
                oldPaymentMethod:oldObj5?.payMethodId
            });
        }
        if (serviceData.farmPro.present){
            var oldObj6=invoiceData[selectedMonth].services.find(x=> {return x.name===6});
            data.transactions.push({
                monthlyInvoiceId: serviceData.farmPro.monthlyInvoiceId,
                serviceName: 6,
                subServiceId: serviceData.farmPro.subServiceId,
                net: serviceData.farmPro.net,
                invoiceMonth: month,
                paymentMethod: serviceData.farmPro.payMethod,
                serviceId:serviceData.farmPro.serviceId,
                oldNet:oldObj6?.net,
                oldPaymentMethod:oldObj6?.payMethodId
            });
        }
        if (serviceData.payroll.present){
            var oldObj7=invoiceData[selectedMonth].services.find(x=> {return x.name===7});
            data.transactions.push({
                monthlyInvoiceId: serviceData.payroll.monthlyInvoiceId,
                serviceName: 7,
                subServiceId: serviceData.payroll.subServiceId,
                net: serviceData.payroll.net,
                invoiceMonth: month,
                paymentMethod: serviceData.payroll.payMethod,
                serviceId:serviceData.payroll.serviceId,
                oldNet:oldObj7?.net,
                oldPaymentMethod:oldObj7?.payMethodId
            });
        }
        if (serviceData.unpaidDD.present){
            var oldObj8=invoiceData[selectedMonth].services.find(x=> {return x.name===8});
            data.transactions.push({
                monthlyInvoiceId: serviceData.unpaidDD.monthlyInvoiceId,
                serviceName: 8,
                subServiceId: serviceData.unpaidDD.subServiceId,
                net: serviceData.unpaidDD.net,
                invoiceMonth: month,
                paymentMethod: serviceData.unpaidDD.payMethod,
                serviceId:serviceData.unpaidDD.serviceId,
                oldNet:oldObj8?.net,
                oldPaymentMethod:oldObj8?.payMethodId
            });
        }
        if (serviceData.prepaymentTopUp.present){
            var oldObj9=invoiceData[selectedMonth].services.find(x=> {return x.name===9});
            data.transactions.push({
                monthlyInvoiceId: serviceData.prepaymentTopUp.monthlyInvoiceId,
                serviceName: 9,
                subServiceId: serviceData.prepaymentTopUp.subServiceId,
                net: serviceData.prepaymentTopUp.net,
                invoiceMonth: month,
                paymentMethod: serviceData.prepaymentTopUp.payMethod,
                serviceId:serviceData.prepaymentTopUp.serviceId,
                oldNet:oldObj9?.net,
                oldPaymentMethod:oldObj9?.payMethodId
            });
        }
        const token = await GetAccessToken(msal);
        await PostMonthlyInvocie(token, data);

        const newData = await GetMonthlyInvoiceData(token, context.client.code);

        setMonthList(newData.months);
        setDisplayMonths(newData.displayMonth);
        setInvoiceData(newData.invoices);
        setIsDirty(false);
    }

    if (isLoading)
        return (
            <div style={{ width: "100%", height: "100%", minHeight: "inherit", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <Spin size="large" />
            </div>
        )
    if (invoiceData === null)
        return (
            <p style={{ padding: "20px" }}>Select a client to view monthly invoices.</p>
        )
    else
        return (
            <div className="div-monthly-invoice div-non-ant-table">
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", padding: "16px" }}>
                    <h3 style={{ marginRight: "50px" }}>Invoice Month</h3>
                    <StandardSelect items={displayMonths} selectedItem={displayMonths[selectedMonth]} onChange={onMonthChanged} invertList = {true}/>
                </div>
                <form>
                <table style={{position:"relative", background:"white"}}>
                    <thead>
                        <tr>
                            <th>Service</th>
                            <th>Category Code  / Level</th>
                            <th>Pay Method </th>
                            <th>Net</th>
                            <th>Gross</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            serviceData.bookeepingLocal.present &&
                            <tr>
                                <td>Bookeeping Local Office</td>
                                <td style={{ textAlign: "left" }}></td>
                                <td style={{ textAlign: "center" }}>
                                    <PayMethodSelect value={serviceData.bookeepingLocal.payMethod} isEditable={(!isNewClient&& editAuthorized && monthEditable)} onChange={(val) => setData(val, "bookeepingLocal", "payMethod")} />
                                </td>
                                <td style={{ textAlign: "right" }}><NetInput value={serviceData.bookeepingLocal.net} isEditable={(!isNewClient&& editAuthorized && monthEditable)} onChange={(e) => setData(e, "bookeepingLocal", "net")} /></td>
                                <td style={{ textAlign: "right" }}>{getGross(serviceData.bookeepingLocal)?.toLocaleString(undefined, { minimumFractionDigits: 2 })}</td>
                            </tr>
                        }
                        {
                            serviceData.bookeepingRecording.present &&
                            <tr className="border-top">
                                <td style={{ textDecoration: "underline" }}>Bookeeping Recording</td>
                                <td style={{ textAlign: "left" }}>{serviceData.bookeepingRecording.level}</td>
                                <td style={{ textAlign: "center" }}>
                                    <PayMethodSelect value={serviceData.bookeepingRecording.payMethod} isEditable={(!isNewClient&& editAuthorized && monthEditable && serviceData.bookeepingRecording.paymentEditable)} onChange={(val) => setData(val, "bookeepingRecording", "payMethod")} />
                                </td>
                                <td></td>
                                <td></td>
                            </tr>
                        }
                        {
                            serviceData.poac.present &&
                            <tr>
                                <td style={{ paddingLeft: "36px" }}>POAC</td>
                                <td ></td>
                                <td></td>
                                <td style={{ textAlign: "right" }}>{serviceData.poac.net?.toLocaleString(undefined, { minimumFractionDigits: 2 })}</td>
                                <td style={{ textAlign: "right" }}>{getGross(serviceData.poac)?.toLocaleString(undefined, { minimumFractionDigits: 2 })}</td>
                            </tr>
                        }
                        {
                            serviceData.prepaymentStandard.present &&
                            <tr>
                                <td style={{ paddingLeft: "36px" }}>Prepayment Standard</td>
                                <td></td>
                                <td></td>
                                <td style={{ textAlign: "right" }}>{serviceData.prepaymentStandard.net?.toLocaleString(undefined, { minimumFractionDigits: 2 })}</td>
                                <td style={{ textAlign: "right" }}>{getGross(serviceData.prepaymentStandard)?.toLocaleString(undefined, { minimumFractionDigits: 2 })}</td>
                            </tr>
                        }
                        {
                            (serviceData.vat.present && serviceData.bookeepingRecording.present) &&
                            <tr>
                                <td style={{ paddingLeft: "36px" }}>VAT by IFAC</td>
                                <td></td>
                                <td></td>
                                <td style={{ textAlign: "right" }}><NetInput value={serviceData.vat.net} isEditable={(!isNewClient&& editAuthorized && monthEditable)} onChange={(e) => setData(e, "vat", "net")} /></td>
                                <td style={{ textAlign: "right" }}>{getGross(serviceData.vat)?.toLocaleString(undefined, { minimumFractionDigits: 2 })}</td>
                            </tr>
                        }
                        {
                            serviceData.shareCapital.present &&
                            <tr>
                                <td style={{ paddingLeft: "36px" }}>Share Capital</td>
                                <td></td>
                                <td></td>
                                <td style={{ textAlign: "right" }}><NetInput value={serviceData.shareCapital.net} isEditable={(!isNewClient&& editAuthorized && monthEditable)} onChange={(e) => setData(e, "shareCapital", "net")} /></td>
                                <td style={{ textAlign: "right" }}>{serviceData.shareCapital.net?.toLocaleString(undefined, { minimumFractionDigits: 2 })}</td>
                            </tr>
                        }
                        {
                            serviceData.bookeepingRecording.present &&
                            <tr className="border-bottom">
                                <td style={{ paddingLeft: "36px" }}>Subtotal</td>
                                <td></td>
                                <td></td>
                                <td style={{ textAlign: "right" }}>{getBRNetTotal()?.toLocaleString(undefined, { minimumFractionDigits: 2 })}</td>
                                <td style={{ textAlign: "right" }}>{getBRGrossTotal()?.toLocaleString(undefined, { minimumFractionDigits: 2 })}</td>
                            </tr>
                        }
                        {
                            serviceData.farmPro.present &&
                            <tr>
                                <td>Farm Pro</td>
                                <td style={{ textAlign: "left" }}>{serviceData.farmPro.level}</td>
                                <td style={{ textAlign: "center" }}>
                                    <PayMethodSelect value={serviceData.farmPro.payMethod} isEditable={(!isNewClient&& editAuthorized && monthEditable)} onChange={(val) => setData(val, "farmPro", "payMethod")} />
                                </td>
                                <td style={{ textAlign: "right" }}><NetInput value={serviceData.farmPro.net} isEditable={(!isNewClient&& editAuthorized && monthEditable && serviceData.farmPro.isBespoke)} onChange={(e) => setData(e, "farmPro", "net")} /></td>
                                <td style={{ textAlign: "right" }}>{getGross(serviceData.farmPro)?.toLocaleString(undefined, { minimumFractionDigits: 2 })}</td>
                            </tr>
                        }
                        {
                            serviceData.payroll.present &&
                            <tr>
                                <td>Payroll by IFAC</td>
                                <td style={{ textAlign: "left" }}>{serviceData.payroll.level}</td>
                                <td style={{ textAlign: "center" }}>
                                    <PayMethodSelect value={serviceData.payroll.payMethod} isEditable={(!isNewClient&& editAuthorized && monthEditable)} onChange={(val) => setData(val, "payroll", "payMethod")} />
                                </td>
                                <td style={{ textAlign: "right" }}><NetInput value={serviceData.payroll.net} isEditable={(!isNewClient&& editAuthorized && monthEditable && serviceData.payroll.isBespoke)} onChange={(e) => setData(e, "payroll", "net")} /></td>
                                <td style={{ textAlign: "right" }}>{getGross(serviceData.payroll)?.toLocaleString(undefined, { minimumFractionDigits: 2 })}</td>
                            </tr>
                        }
                        {
                            serviceData.unpaidDD.present &&
                            <tr>
                                <td>Unpaid DD</td>
                                <td></td>
                                <td style={{ textAlign: "center" }}>
                                    <PayMethodSelect value={serviceData.unpaidDD.payMethod} isEditable={(!isNewClient&& editAuthorized && monthEditable)} onChange={(val) => setData(val, "unpaidDD", "payMethod")} />
                                </td>
                                <td style={{ textAlign: "right" }}>{serviceData.unpaidDD.net?.toLocaleString(undefined, { minimumFractionDigits: 2 })}</td>
                                <td style={{ textAlign: "right" }}>{getGross(serviceData.unpaidDD)?.toLocaleString(undefined, { minimumFractionDigits: 2 })}</td>
                            </tr>
                        }
                        {
                            serviceData.prepaymentTopUp.present &&
                            <tr>
                                <td>Prepayment Top-up</td>
                                <td></td>
                                <td style={{ textAlign: "center" }}>
                                    <PayMethodSelect value={1} isEditable={false} />
                                </td>
                                <td style={{ textAlign: "right" }}><NetInput value={serviceData.prepaymentTopUp.net} isEditable={(!isNewClient&& editAuthorized && monthEditable)} onChange={(e) => setData(e, "prepaymentTopUp", "net")} /></td>
                                <td style={{ textAlign: "right" }}>{getGross(serviceData.prepaymentTopUp)?.toLocaleString(undefined, { minimumFractionDigits: 2 })}</td>
                            </tr>
                        }
                        <tr style={{ fontWeight: "bold" }}>
                            <td>Total</td>
                            <td></td>
                            <td></td>
                            <td style={{ textAlign: "right" }}>{getNetTotal()?.toLocaleString(undefined, { minimumFractionDigits: 2 })}</td>
                            <td style={{ textAlign: "right" }}>{getGrossTotal()?.toLocaleString(undefined, { minimumFractionDigits: 2 })}</td>
                        </tr>
                    </tbody>
                </table>
                </form>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", paddingTop: "20px" }}>
                    <Authorize permissions={["Monthly Invoice - Edit"]}>
                        <SaveButton style={{ marginRight: "10px" }} callback={saveInvocie} disabled={ isNewClient?true:( !saveEnabled || !isDirty)} />
                        <CancelButton callback={cancelChanges} disabled={( isNewClient?true: !isDirty)} />
                    </Authorize>
                </div>
            </div>
        )
}

const paymentMethods = [
    {
        key: 0,
        text: "No selection"
    },
    {
        key: 1,
        text: "DD"
    },
    {
        key: 2,
        text: "Cash"
    },
]

function PayMethodSelect({ value, isEditable, onChange }) {

    if (isEditable)
        return (
            <Select value={Find(paymentMethods, "key", value)?.text} onChange={onChange}>
                {paymentMethods.map(method => (
                    <Option key={method.key}>{method.text}</Option>
                ))}
            </Select>
        )
    else
        return (
            <p>{Find(paymentMethods, "key", value)?.text}</p>
        )
}

function NetInput({ value, isEditable, onChange }) {

    if (isEditable)
        return (
            <NumericInput value={value} setValue={onChange} />
        )
    else
        return (
            <p>{value?.toLocaleString(undefined, { minimumFractionDigits: 2 })}</p>
        )
}

export default MonthlyInvoicePage;

